<template>
  <div id="amlbot-button">
    <template v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </template>
    <template v-else>
      <v-btn
        @click="sendKycRequest"
        color="primary"
        elevation="0"
        x-large
        class="step-btn">Start verification
      </v-btn>
    </template>
  </div>
</template>

<script>
import Api from "@/api/Api"

export default {
  name: "AmlbotVerificationButton",
  data() {
    return {
      loading: false,
      url: process.env.VUE_APP_API_URL + '/verification/kyc-request',
    }
  },
  computed: {
    //
  },
  mounted() {
    // this.getFormUrl()
  },
  methods: {
    async getFormUrl() {
      try {
        await Api.getVerificationFormUrl().then(({data}) => {
          this.loading = false
          this.url = data.data.form_url
        })
      } catch (e) {
        this.errors = e.response?.data?.errors || {}
      }
    },
    async sendKycRequest() {
      try {
        await Api.getVerificationFormUrl().then(({data}) => {
          window.location.replace(data.data.form_url)
        })
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#amlbot-button{
  display: flex;
  justify-content: center;
  align-items: center;
  //border: 1px solid #F2ECEC;
  //box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.09);
  min-width: 142px;
  min-height: 82px;
  transition: all .3s;
  button{
  //  display: flex;
  //  flex-direction: column;
  //  //border-radius: 10px;
  //  //color: #000;
  //  padding: 15px 25px;
  //  font-size: 20px;
  //  font-weight: 500;
  //  align-items: center;
  //  cursor: pointer;
  //  transition: all .3s;
    text-transform: uppercase !important;
  }
  //&:hover{
  //  transform: scale(1.05);
  //}
  img{
    max-width: 70px;
  }
  span {
    padding-bottom: 5px;
  }
}
</style>
