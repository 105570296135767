<template>
  <div class="d-flex flex-1 flex-column justify-center align-center text-center verification-error-container">
    <img src="@/assets/img/verification_error.svg" alt="">
    <h1>Verification <span>error</span></h1>
    <p>Maybe something went wrong!</p>
    <div class="step-btn-container">
      <v-btn
        @click="tryVerificationAgain()"
        color="primary"
        elevation="0"
        x-large
        class="step-btn">Please try again
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdentityVerificationError",
  methods: {
    tryVerificationAgain(){
      this.$emit("restartDashboardVerification")
    },
  },
}
</script>
