<template>
  <div class="d-flex flex-1 flex-column justify-start align-center text-center verification-progress-container">
    <img
      src="@/assets/img/verify.svg"
      alt="verify"
      :width="$vuetify.breakpoint.mdAndDown ? 114 : 200"
      :height="$vuetify.breakpoint.mdAndDown ? 114 : 200">
    <h1>Application <span>submitted</span></h1>
    <p>Your application will be reviewed within up to 12 hours.</p>
    <p>You will be notified once your documents are reviewed by compliance officer.</p>
  </div>
</template>

<script>
export default {
  name: "IdentityVerificationInProgress"
}
</script>
